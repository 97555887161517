define('milegacy/routes/users/new', ['exports', 'ember', 'milegacy/routes/public-base'], function (exports, _ember, _milegacyRoutesPublicBase) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend({
    'page:title': 'Sign Up',
    'page:banner': 'Sign Up',
    credentials: _ember['default'].inject.service('credentials'),
    model: function model() {
      var credentials = this.get('credentials').getProperties('email', 'password');
      return this.store.createRecord('user', credentials);
    },
    actions: {
      submit: function submit(model) {
        var _this = this;

        return model.save().then(function () {
          return _this.transitionTo('users.success');
        });
      },
      willTransition: function willTransition() {
        var model = this.get('controller.model');
        this.get('credentials').setProperties(model.getProperties('email', 'password'));
        model.rollbackAttributes();
      }
    }
  });

  if (window.location.href.indexOf("new") > -1) {
    var new_account_identifier = document.getElementById("root");
    new_account_identifier.className += " new-account-page-wrap";
  }
});