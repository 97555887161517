define('milegacy/routes/milestone/index', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/utils/countable', 'milegacy/mixins/story-uploader', 'milegacy/utils/sidebar'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyUtilsCountable, _milegacyMixinsStoryUploader, _milegacyUtilsSidebar) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend(_milegacyMixinsStoryUploader['default'], {
    'page:menu': _ember['default'].computed.alias('menu'),
    newStory: function newStory() {
      var store = this.get('store');
      var milestone = this.modelFor('milestone').get('milestone');
      var milestoneSlug = milestone.get('slug');
      var user = this.modelFor('user').get('user');

      if (milestoneSlug === "timecapsules") {
        milestone = null;
      }

      return store.createRecord('story', {
        user: user,
        milestone: milestone,
        audience: 'network'
      });
    },

    beforeModel: function beforeModel(transition) {
      var store = this.get('store');
      var userModel = this.modelFor('user');
      var userId = userModel.get('userId');
      var milestoneId = this.modelFor('milestone').get('milestone.id');
      this._super(transition);
      if (userModel.get('acceptance_flag')) {
        this.transitionTo('authenticate');
      } else {
        return Promise.all([store.query('story', { user_id: userId, filter: { milestone_id: milestoneId } })]);
      }
    },

    model: function model() {
      var milestone = this.modelFor('milestone').get('milestone');
      var milestoneSlug = milestone.get('slug');
      var milestoneId = milestone.get('id');
      var milestoneSlug = milestone.get('slug');
      var store = this.get('store');
      var userModel = this.modelFor('user');
      var user = userModel.get('user');
      var userId = userModel.get('userId');
      var friendship = userModel.get('friendship');
      var stories;
      if (milestoneSlug === "timecapsules") {
        //stories = this.store.peekAll('story');
        stories = (0, _milegacyUtilsCountable.countable)(store, 'timecapsule', ['userId', 'isTimeCapsuleStory'], { user_id: userId, filter: { isTimeCapsuleStory: true } }, { isNew: false, isDeleted: false }, ['-createdAt']);
      } else {
        stories = (0, _milegacyUtilsCountable.countable)(store, 'story', ['userId', 'milestoneId'], { user_id: userId, filter: { milestone_id: milestoneId } }, { isNew: false, isDeleted: false, userId: userId, milestoneId: milestoneId }, ['-createdAt']);
      }

      this.set('menu', (0, _milegacyUtilsSidebar.decorateMilestone)(milestone));

      return _ember['default'].Object.create({
        newStory: this.newStory(),
        milestone: milestone,
        stories: stories,
        user: user,
        friendship: friendship
      });
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});