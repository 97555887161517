define('milegacy/components/story-widget', ['exports', 'ember', 'npm:lodash', 'npm:lodash/array/flatten', 'milegacy/persistors/story', 'npm:moment', 'milegacy/utils/unwrap', 'milegacy/components/milestone-sidebar'], function (exports, _ember, _npmLodash, _npmLodashArrayFlatten, _milegacyPersistorsStory, _npmMoment, _milegacyUtilsUnwrap, _milegacyComponentsMilestoneSidebar) {

  var get = _ember['default'].get;

  var DURATION = 300;
  var offset = {
    top: -60,
    left: -60
  };

  var scrollerClass = '.media-scroller .content';

  var $window = _ember['default'].$(window);

  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service('store'),
    session: _ember['default'].inject.service('session'),
    tagName: 'article',
    classNameBindings: [':story', 'story.isSaving:destroying'],
    isJournal: _ember['default'].computed.empty('story.media'),
    isEditing: false,
    userId: null,
    storyComments: _ember['default'].computed.filterBy('story.comments', 'isNew', false),

    datePickerOptions: {
      max: (0, _npmMoment['default'])().add(_npmMoment['default'].duration(25, 'year')).toDate(),
      selectYears: 100,
      selectMonths: true,
      format: 'yyyy-mm-dd',
      formatSubmit: 'yyyy-mm-dd'
    },

    persistor: (function () {
      var story = (0, _milegacyUtilsUnwrap.unwrap)(this.get('story'));
      var ajax = this.get('ajax');
      return _milegacyPersistorsStory['default'].create({ story: story, ajax: ajax });
    }).property('story'),
    mediaComments: (function () {
      var media = this.get('story.media');
      var comments = media.map(function (m) {
        return get(m, 'comments').toArray();
      });
      comments = (0, _npmLodashArrayFlatten['default'])(comments);
      return comments;
    }).property('story.media.[].content', 'story.media.content.@each.commentCount'),
    commentSource: (function () {
      if (this.get('currentMedia')) {
        return this.get('currentMedia.comments');
      }
      if (this.get('isJournal')) {
        return this.get('storyComments');
      }
      return this.get('mediaComments');
    }).property('storyComments', 'mediaComments', 'currentMedia.comments', 'isJournal'),
    comments: (function () {
      var comments = this.get('commentSource');
      return _npmLodash['default'].chain(comments).filterByEx({ isNew: false }).sortByEx(['-createdAt']).value();
    }).property('commentSource'),
    storyPublishDate: (function () {
      return new Date(this.get('story.publish_date')).toDateString();
    }).property('story.publish_date'),
    eventDateIsUnchanged: (function () {
      return new Date(this.get('story.event_date')).toDateString() == new Date(this.get('story.createdAt')).toDateString();
    }).property('story.event_date'),
    newComment: function newComment() {
      var store = this.get('store');
      var user = this.get('session.currentUser');
      var story = this.get('story');
      var currentMedia = this.get('currentMedia');

      var commentable = currentMedia ? currentMedia : story;

      return store.createRecord('comment', {
        user: user,
        commentable: commentable
      });
    },
    resetComment: function resetComment() {
      var comment = this.get('comment');
      if (comment) {
        comment.rollbackAttributes();
      }
    },
    updateComment: (function () {
      this.resetComment();
      this.set('comment', this.newComment());
    }).observes('isJournal', 'currentMedia').on('init'),
    willDestroyElement: function willDestroyElement() {
      this.resetComment();
    },
    click: function click(event) {
      if (!["icon icon-gray icon-remove-small comment", "icon icon-gray icon-remove-small"].contains(event.target.classList.value)) {
        this.sendAction('interact');
      }
    },
    scrollTo: function scrollTo(media) {
      if (!this.get('isDestroying') && !this.get('isDestroyed')) {
        var $image = this.$('[data-media-id=' + media.get('id') + ']');
        var $scroll = this.$(scrollerClass);

        $window.scrollTo($image, DURATION, { offset: offset });
        $scroll.scrollTo($image, DURATION, { offset: offset });
      }
    },
    toggleEditMode: function toggleEditMode() {
      this.toggleProperty('isEditing');
      this.set('currentMedia', null);
    },
    actions: {
      selectMedia: function selectMedia(media) {
        // If the media is already opened, hide the media.
        if (this.get('currentMedia') === media) {
          this.set('currentMedia', null);
        } else {
          this.set('currentMedia', media);

          if (media) {
            _ember['default'].run.scheduleOnce('afterRender', this, this.scrollTo, media);
          }
        }
      },
      comment: function comment(_comment) {
        var _this = this;

        _comment.save().then(function () {
          return _this.updateComment();
        });
      },
      remove: function remove() {
        var _this2 = this;

        if (this.get('removeAction')) {
          this.sendAction('removeAction');
        } else {
          (0, _milegacyUtilsUnwrap.unwrap)(this.get('story')).destroyRecord();
        }
        var currentUser = this.get('session.currentUser');
        this.set('userId', currentUser.get('id'));
        var filter = { user_id: this.get('userId') };
        setTimeout(function () {
          (0, _milegacyComponentsMilestoneSidebar.refresh)(_this2.get('store'), 'story', filter);
        }, 2000);
      },
      toggleEdit: function toggleEdit() {
        this.toggleEditMode();
      },

      refresh: function refresh() {
        this.sendAction('refresh');
      },
      closeModal: function closeModal() {
        var persistor = this.get('persistor');
        persistor.save();
      }
    }
  });
});