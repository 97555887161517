define('milegacy/routes/index', ['exports', 'ember', 'milegacy/routes/base'], function (exports, _ember, _milegacyRoutesBase) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    session: _ember['default'].inject.service('session'),
    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var currentUser = this.get('session.currentUser');
      if (currentUser && currentUser.content.get('acceptance_flag')) {
        this.transitionTo('notifications');
      } else {
        this.transitionTo('authenticate');
      }
    }
  });
});