define('milegacy/controllers/authenticate', ['exports', 'ember', 'npm:moment'], function (exports, _ember, _npmMoment) {
  exports['default'] = _ember['default'].Controller.extend({

    auth_status: true,
    datePickerOptions: {
      max: (0, _npmMoment['default'])().subtract(_npmMoment['default'].duration(13, 'year')).toDate(),
      selectYears: 100,
      selectMonths: true,
      format: 'yyyy-mm-dd',
      formatSubmit: 'yyyy-mm-dd'
    },

    enableContinue: (function () {
      if (this.get('model.dob') && _npmMoment['default'].duration((0, _npmMoment['default'])() - (0, _npmMoment['default'])(this.get('model.dob'))).years() < 13) {
        this.set('model.dob', '');
        _ember['default'].$('.dob-msg').click();
      }

      this.model.set('acceptance_flag', true);
      return this.validUserDetail(this.model);
    }).property('model', 'model.acceptance_flag', 'model.gender', 'model.dob'),

    validUserDetail: function validUserDetail(model) {
      if (model.get('gender') != null && model.get('dob') != undefined && model.get('dob') !== null) {
        return model.get('acceptance_flag') && model.get('dob').toString().trim().length > 0 && model.get('gender').trim().length > 0;
      } else {
        return false;
      }
    },

    actions: {
      handleCheckboxChange: function handleCheckboxChange() {
        this.set('auth_status', event.target.checked);
      }
    }

  });
});