define('milegacy/routes/authenticate', ['exports', 'ember', 'milegacy/routes/base'], function (exports, _ember, _milegacyRoutesBase) {
  exports['default'] = _milegacyRoutesBase['default'].extend({
    'page:title': 'Authenticate',
    'page:banner': 'MiLegacy User Authentication',

    session: _ember['default'].inject.service('session'),

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      var currentUser = this.get('session.currentUser');
      if (currentUser && currentUser.content.get('acceptance_flag')) {
        this.transitionTo('notifications');
      }
    },

    model: function model() {
      this.set('menu', _ember['default'].Object.create({
        text: 'Authenticate'
      }));
      return this.get('session.currentUser');
    },

    actions: {
      cancelAndLogout: function cancelAndLogout() {
        return this.get('session').invalidate();
      },

      loginAndContinue: function loginAndContinue(model) {
        var _this = this;

        model.set('birthday', model.get('dob'));
        model.save().then(function () {
          return location.reload();
        }, function (model) {
          return _this.set('errors', model.errors);
        });
      }
    }
  });
});