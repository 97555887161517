define('milegacy/components/story-control-bar', ['exports', 'ember', 'milegacy/utils/unwrap', 'npm:moment'], function (exports, _ember, _milegacyUtilsUnwrap, _npmMoment) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    classNameBindings: [':control-bar'],
    publishDate: (0, _npmMoment['default'])(_ember['default'].computed.alias('story.publish_date')),
    visibilityOptionsList: [{ slug: 'anyone', name: 'Anyone', icon: 'world' }, { slug: 'network', name: 'My Network', icon: 'network' }, { slug: 'only_me', name: 'Only Me', icon: 'lock' }, { slug: 'individual', name: 'Friend', icon: 'me' }],

    datePickerOptions: {
      max: (0, _npmMoment['default'])().add(_npmMoment['default'].duration(25, 'year')).toDate(),
      min: (0, _npmMoment['default'])(),
      selectYears: 100,
      selectMonths: true,
      format: 'yyyy-mm-dd',
      formatSubmit: 'yyyy-mm-dd'
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('publishDate', (0, _npmMoment['default'])(this.get('story.publish_date')));
    },
    currentVisibility: (function () {
      if (this.get('story.audience') != "individual") {
        this.set('story.friendId', '');
      }
      return this.get('visibilityOptions').findBy('slug', this.get('story.audience'));
    }).property('story.audience'),

    visibilityOptions: (function () {
      if (this.get('isEditing') && this.get('story.isAddedAsTimeCapsuleStory')) {
        return this.visibilityOptionsList.filter(function (option) {
          return option.slug != "only_me";
        });
      }
      return this.visibilityOptionsList;
    }).property('story.audience', 'isEditing'),

    isFriendAudience: (function () {
      return this.get('story.audience') == "individual";
    }).property('story.audience'),

    isPublishDateChangeAllowed: (function () {
      return this.get('story.isTimeCapsuleStory') && this.get('isEditing');
    }).property('story'),

    milestoneOptions: (function () {
      var milestones = this.get('store').peekAll('milestone').sortBy('sort');
      var timecapsule = milestones.filterBy('slug', 'timecapsules');
      milestones = milestones.removeObject(timecapsule ? timecapsule[0] : '');
      return milestones;
    }).property(),

    friendsOption: (function () {
      return Promise.all([this.get('store').findAll('friendship')]);
    }).property(),

    currentMilestone: (function () {
      var milestone = this.get('story.milestone');

      if (_ember['default'].isBlank((0, _milegacyUtilsUnwrap.unwrap)(milestone))) {
        return _ember['default'].Object.create({ slug: 'all', color: 'gray' });
      } else {
        return milestone;
      }
    }).property('story.milestone'),
    milestone: _ember['default'].computed('story.milestone', {
      get: function get() {
        return (0, _milegacyUtilsUnwrap.unwrap)(this.get('story.milestone'));
      },
      set: function set(key, value) {
        this.set('story.milestone', value);
        return value;
      }
    }),
    actions: {
      closeModal: function closeModal() {
        this.sendAction('setDateValue', this.get('publishDate'));
      },

      showError: function showError(errorText) {
        this.sendAction('showError', errorText);
      }
    }

  });
});