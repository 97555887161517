define('milegacy/routes/login', ['exports', 'ember', 'milegacy/routes/public-base', 'milegacy/mixins/operation-proxy', 'milegacy/config/environment'], function (exports, _ember, _milegacyRoutesPublicBase, _milegacyMixinsOperationProxy, _milegacyConfigEnvironment) {
  exports['default'] = _milegacyRoutesPublicBase['default'].extend(_milegacyMixinsOperationProxy['default'], {
    'page:title': 'MiLegacy',
    'page:banner': 'MiLegacy',
    credentials: _ember['default'].inject.service('credentials'),
    session: _ember['default'].inject.service('session'),
    wpLinks: _milegacyConfigEnvironment['default'].wpLinks,
    user: '',

    model: function model() {
      var _get$getProperties = this.get('credentials').getProperties('email', 'password');

      var email = _get$getProperties.email;
      var password = _get$getProperties.password;

      var firstName = "";
      var lastName = "";
      var dob = "";
      var gender = "";

      return _ember['default'].Object.create({
        email: email, password: password, firstName: firstName, lastName: lastName, dob: dob, gender: gender,
        operation: this.createOperation()
      });
    },

    actions: {
      authenticate: function authenticate(model) {
        var _this = this;

        var session = this.get('session');
        var isAuthenticated = session.get('isAuthenticated');

        var promise = session.authenticate('authenticator:application', model.email, model.password)['catch'](function (data) {
          model.set('error', data.error);
        }).then(function () {
          session.set('loaded', false);
          if (isAuthenticated) {
            _this.transitionTo('index');
          }
        });

        this.updateOperation(promise);
      },

      hamburgerClick: function hamburgerClick() {
        var isClassActive = $('.nav-header div').hasClass('is-active');
        if (isClassActive) {
          $('.nav-header div').removeClass("is-active");
          document.getElementById('menu-header-right-menu').style.display = "none";
        } else {
          $('.nav-header div').addClass("is-active");
          document.getElementById('menu-header-right-menu').style.display = "block";
        }
      },
      willTransition: function willTransition() {
        var model = this.get('controller.model');
        this.get('credentials').setProperties(model.getProperties('email', 'password'));

        return true;
      }
    },

    validUserDetail: function validUserDetail(model) {
      return model.get('firstName').trim().length > 0 && model.get('email').trim().length > 0 && model.get('password').trim().length > 0 && model.get('dob').toString().trim().length > 0 && model.get('gender').trim().length > 0;
    }

  });

  // setTimeout(function(){
  // if(window.location.href.indexOf("login") > -1) {
  // var login_identifier = document.getElementById("root");
  //   login_identifier.className += " login-page-wrap";
  // }
  // }, 10);

  if (window.location.pathname == '/') {
    var login_identifier = document.getElementById("root");
    login_identifier.className += " login-page-wrap";
  } else {
    // do nothing
    setTimeout(function () {
      if (window.location.href.indexOf("login") > -1) {
        var login_identifier = document.getElementById("root");
        login_identifier.className += " login-page-wrap";
      }
    }, 10);
    if (window.location.href.indexOf("wp/login") > -1) {
      var login_identifier = document.getElementById("root");
      login_identifier.className += " wp-login-page-wrap";
    }
  }
});